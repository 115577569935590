@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom scrollbar styles for Webkit browsers (Chrome, Safari, etc.) */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #F1F5F9;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #CBD5E1;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #94A3B8;
}

html, 
body {
  overscroll-behavior: none;
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
}